import axios from "axios";
import { base_url } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
// Action Types
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILURE = "GET_CART_FAILURE";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILURE = "ADD_TO_CART_FAILURE";
export const DELETE_FROM_CART_SUCCESS = "DELETE_FROM_CART_SUCCESS";
export const DELETE_FROM_CART_FAILURE = "DELETE_FROM_CART_FAILURE";
export const GET_CART_TOTAL = "GET_CART_TOTAL";
export const CART_ERROR = "CART_ERROR";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const GET_PRODUCT_QUANTITY_SUCCESS = "GET_PRODUCT_QUANTITY_SUCCESS";
export const GET_PRODUCT_QUANTITY_FAILURE = "GET_PRODUCT_QUANTITY_FAILURE";
export const CLEAR_CART_SUCCESS = "CLEAR_CART_SUCCESS";
export const CLEAR_CART_FAILURE = "CLEAR_CART_FAILURE";

// Fetch cart items
export const getCart = () => async (dispatch) => {
  try {
    // Retrieve customer data from localStorage
    const customerData = JSON.parse(localStorage.getItem("customer"));

    // Check if customer data and token exist
    if (!customerData || !customerData.token) {
      throw new Error("User is not authenticated or token is missing.");
    }

    // Extract token from customer data
    const token = customerData.token;

    // Set the request configuration with authorization header
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    // Send GET request to fetch cart items
    const response = await axios.get(`https://wibfashionapi.mindhackers.org/api/cart`, config);

    // Dispatch success action if request is successful
    dispatch({
      type: GET_CART_SUCCESS,
      payload: response.data.cart_items, // Ensure payload matches the data structure
    });
  } catch (error) {
    // Handle any errors and dispatch failure action
    console.error(
      "Error fetching cart:",
      error.response ? error.response.data : error.message
    );
    dispatch({
      type: GET_CART_FAILURE,
      payload: error.message,
    });
  }
};

// Add to cart
// cartAction.js
export const addToCart =
  ({ productId, navigate, selectedColor, selectedSize }) =>
  async (dispatch) => {
    try {
      // Retrieve customer data from localStorage
      const customerData = JSON.parse(localStorage.getItem("customer"));

      if (!customerData || !customerData.token) {
        toast.error("Please log in to add items to your cart.");

        // Use the navigate function passed from the component to redirect to login
        return navigate("/login");
      }

      const token = customerData.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      // Send product details to the backend
      const response = await axios.post(
        `${base_url}cart/add`,
        { productId, selectedColor, selectedSize },
        config
      );

      dispatch({
        type: ADD_TO_CART_SUCCESS,
        payload: response.data,
      });

      // Optionally, fetch updated cart data
      dispatch(getCartTotal());
      dispatch(getCart());

      toast.success("Product added to cart successfully 🥳");
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      dispatch({
        type: ADD_TO_CART_FAILURE,
        payload: error.message,
      });
      toast.error("Failed to add item to cart.");
    }
  };

// Remove from cart
export const deleteFromCart = (productId) => async (dispatch) => {
  try {
    // Retrieve customer data from localStorage
    const customerData = JSON.parse(localStorage.getItem("customer"));

    // Check if customer data and token exist
    if (!customerData || !customerData.token) {
      throw new Error("User is not authenticated or token is missing.");
    }

    // Extract token from customer data
    const token = customerData.token;

    // Set the request configuration with authorization header
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    // Making DELETE request to the server with productId in the body
    const response = await axios.delete(`${base_url}cart/remove`, {
      ...config,
      data: { productId },
    });

    // Dispatch action for successful product removal
    dispatch({
      type: DELETE_FROM_CART_SUCCESS,
      payload: productId, // This will be used to remove the item from the state
    });

    dispatch(getCart());
    dispatch(getCartTotal());
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );

    // Dispatch failure action if there was an error during the process
    dispatch({
      type: DELETE_FROM_CART_FAILURE,
      payload: error.response ? error.response.data.message : error.message,
    });
  }
};
export const increaseQuantity = (id) => async (dispatch, getState) => {
  try {
    const customerData = JSON.parse(localStorage.getItem("customer"));
    if (!customerData || !customerData.token) {
      throw new Error("User is not authenticated or token is missing.");
    }

    const token = customerData.token;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    // Send request to increase quantity
    const { data } = await axios.post(
      `${base_url}cart/increase`,
      { productId: id },
      config
    );

    // Dispatch the updated cart item to the Redux store
    dispatch({
      type: INCREASE_QUANTITY,
      payload: data, // Assuming the server returns the updated cart item
    });

    // Optionally, recalculate the total cart value
    dispatch(getCartTotal());
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
  }
};
// Decrease quantity in cart
export const decreaseQuantity = (id) => async (dispatch) => {
  try {
    const customerData = JSON.parse(localStorage.getItem("customer"));
    if (!customerData || !customerData.token) {
      throw new Error("User is not authenticated or token is missing.");
    }

    const token = customerData.token;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    // Send request to decrease quantity
    const { data } = await axios.post(
      `${base_url}cart/decrease`,
      { productId: id },
      config
    );

    dispatch({
      type: DECREASE_QUANTITY,
      payload: data,
    });

    dispatch(getCartTotal());
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
  }
};

export const getCartTotal = () => async (dispatch) => {
  try {
    // Retrieve customer data and token from localStorage
    const customerData = JSON.parse(localStorage.getItem("customer"));

    if (!customerData || !customerData.token) {
      throw new Error("User is not authenticated or token is missing.");
    }

    const token = customerData.token;

    // Send GET request to fetch total quantity
    const response = await axios.get(`${base_url}cart/total`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    // Dispatch action to store the total quantity in the Redux store
    dispatch({
      type: GET_CART_TOTAL,
      payload: response.data.total_quantity,
    });
   
  } catch (error) {
    // Dispatch error action
    dispatch({
      type: CART_ERROR,
      payload: error.message,
    });
  }
};

export const clearCart = () => async (dispatch) => {
  try {
    // Retrieve customer data from localStorage
    const customerData = JSON.parse(localStorage.getItem("customer"));

    if (!customerData || !customerData.token) {
      throw new Error("User is not authenticated or token is missing.");
    }

    const token = customerData.token;

    // Set the request configuration with authorization header
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    // Making DELETE request to clear the cart
    const response = await axios.delete(`${base_url}cart/clear`, config);

    // Dispatch action for successful cart clearance
    dispatch({
      type: CLEAR_CART_SUCCESS,
    });

    // Optionally, you may fetch the updated cart
    dispatch(getCart());
    dispatch(getCartTotal());
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );

    // Dispatch failure action if there was an error during the process
    dispatch({
      type: CLEAR_CART_FAILURE,
      payload: error.response ? error.response.data.message : error.message,
    });
  }
};
