// src/components/OfflineNotice.js
import React, { useState, useEffect } from 'react';

const OfflineNotice = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    if (isOnline) return null;
    function onRetry() {
        window.location.reload();
    }

    return (
        <div className="flex items-center justify-center h-screen sm:-mt-64 md:mt-28 ">
            <div className="bg-white rounded-lg shadow-md p-8 text-center">
                <div className="mb-6">
                    <img src='/assets/img/ofline.jpg' alt='no connection' />
                </div>
                <h2 className="text-2xl font-semibold mb-2">Oops!</h2>
                <p className="text-gray-500 mb-6">
                    There is no Internet connection<br />
                    Please check your Internet connection
                </p>
                <button
                    onClick={onRetry}
                    className="bg-red-500 text-white py-2 px-4 rounded-full hover:bg-red-700 transition duration-300">
                    Refersh
                </button>
            </div>
        </div>
    );
};

export default OfflineNotice;
