const initialState = {
  items: [],
  totalItems: 0,
  loading: false,
  error: null,
  totalQuantity: 0,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CART_SUCCESS":
      return {
        ...state,
        items: action.payload,
        totalItems: action.payload.reduce(
          (total, item) => total + item.quantity,
          0
        ),
        loading: false,
        error: null,
      };
    case "GET_CART_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "ADD_TO_CART_SUCCESS":
      return {
        ...state,
        items: [...state.items, action.payload],
        totalItems: state.totalItems + action.payload.quantity,
      };

    case "DELETE_FROM_CART_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "DELETE_FROM_CART_SUCCESS":
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload), // Filter out the item with the matching id
        totalItems: state.totalItems - 1, // Update the totalItems count
        loading: false,
        error: null,
      };

    case "GET_CART_TOTAL_SUCCESS":
      return {
        ...state,
        totalItems: action.payload,
      };
    case "GET_CART_TOTAL_FAILURE":
      return {
        ...state,
        error: action.payload,
      };
    case "INCREASE_QUANTITY":
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.payload
            ? { ...item, quantity: item.quantity + 1 }
            : item
        ),
        totalItems: state.totalItems + 1,
      };
    case "DECREASE_QUANTITY":
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.payload
            ? { ...item, quantity: Math.max(item.quantity - 1, 1) } // Prevent quantity from going below 1
            : item
        ),
        totalItems: state.totalItems - 1,
      };
    case "GET_CART_TOTAL":
      return {
        ...state,
        totalQuantity: action.payload,
      };
    case "CLEAR_CART_SUCCESS":
      return {
        ...state,
        items: [],
        totalItems: 0,
      };
    case "CLEAR_CART_FAILURE":
      return {
        ...state,
        error: action.payload,
      };
    case "CART_ERROR":
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default cartReducer;
