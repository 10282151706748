import React, { createContext, useState, useEffect } from 'react';
import axios from "axios";
import { base_url } from '../utils/axiosConfig';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (!navigator.onLine) {
                console.error('You are offline. Please check your internet connection.');
                return;
            }
            try {
                const response = await axios.get(`${base_url}product`);
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedCategory) {
            const filtered = data.filter(product => product.subcategory === selectedCategory);
            setFilteredProducts(filtered);
        } else {
            setFilteredProducts(data);
        }
    }, [selectedCategory, data]);

    return (
        <DataContext.Provider value={{ data, loading, error, selectedCategory, setSelectedCategory, filteredProducts }}>
            {children}
        </DataContext.Provider>
    );
};