import axios from "axios";
import { base_url, config } from "../../utils/axiosConfig";

const register = async (userData) => {
    const response = await axios.post(`${base_url}user/register`, userData);
    console.log('API response:', response);
    if (response.data) {
        return response.data;
    }
};

const login = async (userData) => {
    const response = await axios.post(`${base_url}user/login`, userData);

    if (response.data) {
        localStorage.setItem("customer", JSON.stringify(response.data));
    }
    return response.data;
};



const createOrder = async (orderDetail) => {
    const response = await axios.post(
        `${base_url}user/cart/create-order/`,
        orderDetail,
        config
    );
    if (response.data) {
        return response.data;
    }
};

const getUserOrders = async () => {
    const response = await axios.get(`${base_url}user/getmyorders`, config);

    if (response.data) {
        return response.data;
    }
};

const updateUser = async (data) => {
    const response = await axios.put(
        `${base_url}user/edit-user`,
        data.data,
        data.config2,
        config
    );

    if (response.data) {
        return response.data;
    }
};

const forgotPasswordToken = async (data) => {
    const response = await axios.post(
        `${base_url}user/forgot-password-token`,
        data
    );

    if (response.data) {
        return response.data;
    }
};

const resetPass = async (data) => {
    const response = await axios.put(
        `${base_url}user/reset-password/${data.token}`,
        {
            password: data?.password,
        }
    );

    if (response.data) {
        return response.data;
    }
};

const emptyCart = async (data) => {
    const response = await axios.delete(`${base_url}user/empty-cart`, data);

    if (response.data) {
        return response.data;
    }
};

export const authService = {

    register,
    login,
    createOrder,
    getUserOrders,
    updateUser,
    forgotPasswordToken,
    resetPass,
    emptyCart,
};