import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCartTotal  } from "../../redux/actions/cartAction";
import { getWishlistTotal } from '../../redux/actions/wishListActon';

function Hmain3() {
  const dispatch = useDispatch();
  const totalQuantity = useSelector(state => state.cart.totalQuantity);
  const totalItems = useSelector((state) => state.wishlist.totalItems);

  useEffect(() => {
    dispatch(getCartTotal());
}, [dispatch]);
useEffect(() => {
  dispatch(getWishlistTotal());
}, [dispatch]);

  return (
    <div className="Appp">
      {" "}
      <header className="header__section">
        <div className="offcanvas__stikcy--toolbar">
          <ul className="d-flex justify-content-between">
            <li className="offcanvas__stikcy--toolbar__list -ml-2">
              <Link className="offcanvas__stikcy--toolbar__btn" to="/">
                <span className="offcanvas__stikcy--toolbar__icon center-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    width="21.51"
                    height="21.443"
                    viewBox="0 0 22 17"
                  >
                    <path
                      fill="currentColor"
                      d="M20.9141 7.93359c.1406.11719.249.26953.249.45703 0 .14063-.0469.25782-.1406.35157l-.3516.42187c-.1172.14063-.2578.2494-.4219.2494-.1406 0-.2578-.04688-.3515-.14062l-.9844-.77344V15c0 .3047-.1172.5625-.3516.7734-.249.2344-.4687.3516-.7734.3516h-4.5c-.3047 0-.5742-.1172-.8086-.3516-.249-.249-.3164-.4687-.3164-.7734v-3.6562h-2.25V15c0 .3047-.11719.5625-.35156.7734-.2494.2344-.46875.3516-.77344.3516h-4.5c-.30469 0-.57422-.1172-.80859-.3516-.2494-.249-.31641-.4687-.31641-.7734V8.46094l-.94922.77344c-.11719.09374-.24609.14062-.38672.14062-.16406 0-.30468-.07031-.42187-.2494l-.35157-.42187C.921875 8.625.875 8.50781.875 8.39062c0-.1875.070312-.33984.2494-.45703L9.73438.832031C4.494.527344 4.5312.375 11 .375s.8906.152344 1.2656.457031l8.6485 7.41559zm-3.7266 6.50391V7.05469L11 1.99219l-6.1875 5.0625v7.38281h3.375v-3.6563c0-.3046.4547-.5624.31641-.7734.23437-.23436.5039-.35155.80859-.35155h3.375c.3047 0 .5625.11719.7734.35155.2344.211.3516.4688.3516.7734v3.6563h3.375z"
                    />
                  </svg>
                </span>
                <span className="offcanvas__stikcy--toolbar__label">All</span>
              </Link>
            </li>
            <li className="offcanvas__stikcy--toolbar__list -ml-28">
              <Link className="offcanvas__stikcy--toolbar__btn" to="/shop">
                <span className="offcanvas__stikcy--toolbar__icon center-svg">
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.51"
                    height="17.443"
                    viewBox="0 0 448 512"
                  >
                    <path d="M416 32H32A32 32 0 0 0 0 64v384a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32zm-16 48v152H248V80zm-200 0v152H48V80zM48 432V280h152v152zm200 0V280h152v152z" />
                  </svg>
                </span>
                <span className="offcanvas__stikcy--toolbar__label">Shop</span>
              </Link>
            </li>
            <li className="offcanvas__stikcy--toolbar__list -ml-28">
              <Link
                className="offcanvas__stikcy--toolbar__btn search__open--btn"
                to="/category"
                data-offcanvas
              >
                <span className="offcanvas__stikcy--toolbar__icon center-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      fill="currentColor"
                      d="M3 3h8v8H3V3zm10 0h8v8h-8V3zM3 13h8v8H3v-8zm10 0h8v8h-8v-8z"
                    />
                  </svg>
                </span>
                <span className="offcanvas__stikcy--toolbar__label">
                  category
                </span>
              </Link>
            </li>
            <li className="offcanvas__stikcy--toolbar__list -ml-28">
              <Link
                className="offcanvas__stikcy--toolbar__btn search__open--btn"
                to="/cart"
                data-offcanvas
              >
                <span className="offcanvas__stikcy--toolbar__icon center-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                  >
                    <path
                      fill="currentColor"
                      d="M7 18a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm10 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM5 2a1 1 0 0 0 0 2h2.1l.9 4.5L7 10a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h11a1 1 0 0 0 0-2H7.42l.58-3h9.36a1 1 0 0 0 .98-.8l1.5-7A1 1 0 0 0 18.89 2H5z"
                    />
                  </svg>
                </span>
                <span className="offcanvas__stikcy--toolbar__label">cart</span>
                <span className="items__count">
                  {totalQuantity}
                </span>
              </Link>
            </li>
            <li className="offcanvas__stikcy--toolbar__list -ml-28 mr-3">
              <Link className="offcanvas__stikcy--toolbar__btn" to="/wishlist">
                <span className="offcanvas__stikcy--toolbar__icon center-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.541"
                    height="15.557"
                    viewBox="0 0 18.541 15.557"
                  >
                    <path
                      d="M71.775,135.51a5.153,5.153,0,0,1,1.267-1.524,4.986,4.986,0,0,1,6.584.358,4.728,4.728,0,0,1,1.174,4.914,4.458,4.458,0,0,1-2.132,3.808,22.591,22.591,0,0,1-5.4,4.558c-.445.282-.9.549-1.356.812a.306.306,0,0,1-.254.013,25.491,25.491,0,0,1-6.279-4.8,11.648,11.648,0,0,1-2.52-4.009,4.957,4.957,0,0,1,.028-3.787,4.629,4.629,0,0,1,3.744-2.863,4.782,4.782,0,0,1,5.086,2.447c.013.019.025.034.057.076Z"
                      transform="translate(-62.498 -132.915)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <span className="offcanvas__stikcy--toolbar__label">
                  wishlist
                </span>
                <span className="items__count wishlist">
                  {totalItems}
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
}

export default Hmain3;
