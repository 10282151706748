import axios from "axios";
import { base_url, } from "../../utils/axiosConfig";
const getProducts = async () => {

    try {
        const response = await axios.get(`${base_url}product`);
        if (response.data) {
            //console.log('Product data fetched:', response.data); // Add logging
            return response.data;
        }
    } catch (error) {
        console.error('Error fetching products:', error); // Add error logging
        throw error;
    }

};

const getSingleProduct = async (id) => {
    try {
        const response = await axios.get(`${base_url}product/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching blog:', error);
        throw error; // Re-throw the error for Redux to handle
    }

};

;

export const productService = {
    getProducts,
    getSingleProduct,
};