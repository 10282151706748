import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../asset/wib.png";

function Navmobile() {
  const [isOpen, setIsOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsFixed(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleToggle = () => setIsOpen(!isOpen);

  const currentPath = location.pathname;

  return (
    <nav
      className={`sm:hidden md:fixed lg:fixed xl:fixed top-0 left-0 w-full z-20 transition-all duration-300 ease-in-out bg-white shadow-md ${isFixed ? "opacity-100" : "opacity-0"
        }`}
    >
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img
            src={logo}
            className="h-20"
            alt="Logo"
          />
          <span className="self-center text-2xl font-semibold whitespace-nowrap">
            Wib Fashions
          </span>
        </Link>
        <button
          onClick={handleToggle}
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="navbar-dropdown"
          aria-expanded={isOpen}
        >
          <span className="sr-only">Open main menu</span>
          {isOpen ? (
            <span className="text-2xl">&times;</span>
          ) : (
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          )}
        </button>
        <div
          className={`fixed top-0 left-0 w-64 h-full bg-white shadow-2xl transition-transform transform ${isOpen ? "translate-x-0" : "-translate-x-full"
            } ease-in-out duration-300`}
          id="navbar-dropdown"
        >
          <ul className="flex flex-col p-4 space-y-2 mt-20" >
            <li>
              <Link
                to="/category"
                className={`block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 ${currentPath === "/category"
                  ? "border-b-2 border-red-700"
                  : ""
                  }`}
                onClick={() => setIsOpen(false)}
              >
                Categories
              </Link>
            </li>
            <li>
              <Link
                to="/shop"
                className={`block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 ${currentPath === "/shop"
                  ? "border-b-2 border-red-700"
                  : ""
                  }`}
                onClick={() => setIsOpen(false)}
              >
                Shop
              </Link>
            </li>
            <li>
              <Link
                to="/new"
                className={`block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 ${currentPath === "/new-arrivals"
                  ? "border-b-2 border-red-700"
                  : ""
                  }`}
                onClick={() => setIsOpen(false)}
              >
                New Arrivals
              </Link>
            </li>
            <li>
              <Link
                to="/women"
                className={`block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 ${currentPath === "/offers"
                  ? "border-b-2 border-red-700"
                  : ""
                  }`}
                onClick={() => setIsOpen(false)}
              >
                Womens
              </Link>
            </li>
            <li>
              <Link
                to="/men"
                className={`block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 ${currentPath === "/contact"
                  ? "border-b-2 border-red-700"
                  : ""
                  }`}
                onClick={() => setIsOpen(false)}
              >
                Mens
              </Link>
            </li>
            <li>
              <Link
                to="/couple"
                className={`block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 ${currentPath === "/contact"
                  ? "border-b-2 border-red-700"
                  : ""
                  }`}
                onClick={() => setIsOpen(false)}
              >
                Couples
              </Link>
            </li>
            <li>
              <Link
                to="/kid"
                className={`block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 ${currentPath === "/contact"
                  ? "border-b-2 border-red-700"
                  : ""
                  }`}
                onClick={() => setIsOpen(false)}
              >
                Kids
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navmobile;
