import React, { Suspense, lazy, useState, useEffect } from "react";
import "./index.css";
import loader from "./asset/wib.png"
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import { PrivateRoutes } from "./routing/PrivateRoutes";
import { DataProvider } from "./DataContext/DataContext";
import OfflineNotice from "./components/OfflineNotice";
import ErrorBoundary from "./components/ErrorBoundary";

// Lazy load components
const Home = lazy(() => import("./components/Home"));
const MyAccount = lazy(() => import("./components/MyAccount"));
const Wishlist = lazy(() => import("./components/Wishlist"));
const Cart = lazy(() => import("./components/Cart"));
const Woman = lazy(() => import("./components/Woman"));
const Checkout = lazy(() => import("./components/Checkout"));
const ProductDetails = lazy(() => import("./components/ProductDetails"));
const Login = lazy(() => import("./components/Login"));
const Register = lazy(() => import("./components/Signup"));
const About = lazy(() => import("./components/About"));
const Men = lazy(() => import("./components/Men"));
const Kids = lazy(() => import("./components/Kids"));
const Couples = lazy(() => import("./components/Couples"));
const NewIn = lazy(() => import("./components/NewIn"));
const Shop = lazy(() => import("./components/Shop"));
const ProductAll = lazy(() => import("./components/ProductAll"));
const PrivacyAndPolicy = lazy(() => import("./components/PrivacyAndPolicy"));
const Faq = lazy(() => import("./components/Faq"));
const Category = lazy(() => import("./components/Category"));
const Filter = lazy(() => import("./components/Filter"));
const Search = lazy(() => import("./components/Search"));
const Navmobile = lazy(() => import("./components/Navmobile"));


function App() {

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 50000); // Display the loader for 5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <DataProvider>
        <BrowserRouter>
          <Header />
          <OfflineNotice />
          <ErrorBoundary>
            <Suspense fallback={
              showLoader && (
                <div className="fixed inset-0 flex justify-center items-center z-50">

                  <div className="relative flex justify-center items-center">
                    <div className="absolute animate-spin rounded-full h-36 w-36 border-t-4 border-b-4 border-red-500"></div>
                    <img
                      src={loader}
                      className="rounded-full h-32 w-32"
                      alt="Wub-fashion...."
                    />
                  </div>
                </div>
              )
            }
            >
              <Routes>
                <Route path="/" Component={Home} />
                <Route path="/myaccount" Component={MyAccount} />
                <Route
                  path="/wishlist"
                  element={
                    <PrivateRoutes>
                      <Wishlist />
                    </PrivateRoutes>
                  }
                />
                <Route
                  path="/cart"
                  element={
                    <PrivateRoutes>
                      <Cart />
                    </PrivateRoutes>
                  }
                />
                <Route path="/women" Component={Woman} />
                <Route
                  path="checkout"
                  element={
                    <PrivateRoutes>
                      <Checkout />
                    </PrivateRoutes>
                  }
                />
                <Route path="/productdetails/:id" Component={ProductDetails} />
                <Route path="login" Component={Login} />
                <Route path="register" Component={Register} />
                <Route path="/about" Component={About} />
                <Route path="/header" Component={Header} />
                <Route path="/men" Component={Men} />
                <Route path="/kid" Component={Kids} />
                <Route path="/couple" Component={Couples} />
                <Route path="/new" Component={NewIn} />
                <Route path="/shop/:id" Component={Shop} />
                <Route path="/shop" Component={Shop} />
                <Route path="/productall" Component={ProductAll} />
                <Route path="/privacyandpolicy" Component={PrivacyAndPolicy} />
                <Route path="/faq" Component={Faq} />
                <Route path="/category" Component={Category} />
                <Route path="/filter" element={<Filter />} />
                <Route path="/search" element={<Search />} />
                <Route path="/navmobile" Component={Navmobile} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </BrowserRouter>
      </DataProvider >
    </>
  );
}

export default App;
