import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCart,
  deleteFromCart,
  increaseQuantity,
  decreaseQuantity,
  getCartTotal
} from "../redux/actions/cartAction";

function OffcanvasSidebar({ openTrigger, closeTrigger, wrapper }) {
  useEffect(() => {
    const handleBodyClass = (evt) => {
      const eventTarget = evt.target;
      const wrapperSidebar = document.querySelector(wrapper);
      const wrapperOverlay = wrapper.replace(".", "");
      if (!eventTarget.closest(wrapper) && !eventTarget.closest(openTrigger)) {
        wrapperSidebar.classList.remove("active");
        document.body.classList.remove(`${wrapperOverlay}_active`);
      }
    };

    const openTriggerButtons = document.querySelectorAll(openTrigger);
    const closeTriggerButton = document.querySelector(closeTrigger);
    const wrapperSidebar = document.querySelector(wrapper);
    const wrapperOverlay = wrapper.replace(".", "");

    openTriggerButtons.forEach((singleItem) => {
      singleItem.addEventListener("click", (e) => {
        if (e.target.dataset.offcanvas !== null) {
          wrapperSidebar.classList.add("active");
          document.body.classList.add(`${wrapperOverlay}_active`);
          document.body.addEventListener("click", handleBodyClass);
        }
      });
    });

    closeTriggerButton.addEventListener("click", (e) => {
      if (e.target.dataset.offcanvas !== null) {
        wrapperSidebar.classList.remove("active");
        document.body.classList.remove(`${wrapperOverlay}_active`);
        document.body.removeEventListener("click", handleBodyClass);
      }
    });

    return () => {
      openTriggerButtons.forEach((singleItem) => {
        singleItem.removeEventListener("click", handleBodyClass);
      });
      closeTriggerButton.removeEventListener("click", handleBodyClass);
    };
  }, [openTrigger, closeTrigger, wrapper]);

  return null;
}
function Minicart() {
  const dispatch = useDispatch();
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const totalQuantity = useSelector(state => state.cart.totalQuantity);
  const { items } = useSelector((state) => state.cart);
  const formatPriceETB = (price) => {
    return new Intl.NumberFormat('en-ET', {
      style: 'currency',
      currency: 'ETB',
      minimumFractionDigits: 0,
    }).format(price);
  };
  useEffect(() => {
    setCartItems(items);
    calculateTotalPrice(items);
  }, [items],[]);
  useEffect(() => {
    dispatch(getCart());
  }, []);
  useEffect(() => {
    calculateTotalPrice(cartItems); // Recalculate the total price when cartItems change
  }, [cartItems]);
  const handleRemoveFromCart = (productId) => {
    dispatch(deleteFromCart(productId));
  };

  const calculateTotalPrice = (items) => {
    const total = items.reduce(
      (sum, item) => sum + item.productId.price * item.quantity,
      0
    );
    setTotalPrice(total);
  };

  const handleIncrease = async (productId) => {
    await dispatch(increaseQuantity(productId)); // Dispatch the action to increase quantity
    dispatch(getCart()); // Fetch the updated cart
  };

  const handleDecrease = async (productId) => {
    await dispatch(decreaseQuantity(productId)); // Dispatch the action to decrease quantity
    dispatch(getCart()); // Fetch the updated cart
  };
 
  if (!Array.isArray(cartItems)) {
    return <p>No items in the cart</p>;
  }
  const isOffline = !navigator.onLine;

  if (isOffline) {
    return (
      <div className="hidden items-center justify-center">
        <div className="text-center">
          <h2 className="hidden text-2xl font-bold mb-2">Oops!</h2>
          <p className="hidden">
            You are currently offline. Please check your internet connection.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Link className="header__account--btn minicart__open--btn" data-offcanvas>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26.51"
          height="23.443"
          viewBox="0 0 14.706 13.534"
          className="svg ml-[16px]"
        >
          <g transform="translate(0 0)">
            <g>
              <path
                data-name="Path 16787"
                d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z"
                transform="translate(0 -463.248)"
                fill="currentColor"
              />
              <path
                data-name="Path 16788"
                d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z"
                transform="translate(-1.191 -466.622)"
                fill="currentColor"
              />
              <path
                data-name="Path 16789"
                d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z"
                transform="translate(-2.875 -466.622)"
                fill="currentColor"
              />
            </g>
          </g>
        </svg>
        <span className="header__account--btn__text"> My cart</span>
        <span className="items__count">{totalQuantity}</span>
      </Link>

      <div className="offCanvas__minicart">
        <div className="minicart__header ">
          <div className="minicart__header--top d-flex justify-content-between align-items-center">
            <h2 className="minicart__title h3"> Shopping Cart</h2>
            <button
              className="minicart__close--btn"
              aria-label="minicart close button"
              data-offcanvas
            >
              <svg
                className="minicart__close--icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                  d="M368 368L144 144M368 144L144 368"
                />
              </svg>
            </button>
          </div>
          <p className="minicart__header--desc">
            Clothing and fashion products are limited
          </p>
        </div>
        {items.map((item, index) => {
          return (
            <div key={index}>
              <div className="minicart__product">
                <div className="minicart__product--items d-flex">
                  <div className="minicart__thumb">
                    <Link to={`/productdetails/${item?.productId?._id}`}>
                      <img
                        src={
                          item?.productId?.colors?.[0]?.images?.[0]
                            ?.secure_url || "default-image-url.jpg"
                        }
                        alt="product-img"
                      />
                    </Link>
                  </div>
                  <div className="minicart__text">
                    <p className="product__items--content__subtitle">
                      {item?.productId?.category},{" "}
                      {item?.productId?.subcategory}
                    </p>

                    <h3 className="product__items--content__title h4">
                      <Link to={`/productdetails/${item?.productId?._id}`}>
                        {item?.productId?.title}
                      </Link>
                    </h3>

                    <div className="minicart__price gap-4">
                      <span className="text-md font-bold text-gray-700">
                       
                        {formatPriceETB(item?.productId?.price)}
                      </span>
                      <br />
                      <span className="product__items--content__title old__price text-red-600">
                        {item?.productId?.oldPrice}.00 ETB
                       
                      </span>
                    </div>
                    <div className="minicart__text--footer d-flex align-items-center">
                      <div className="quantity__box minicart__quantity">
                        <button
                          onClick={() => handleDecrease(item.productId._id)}
                          className="quantity__value decrease"
                        >
                          -
                        </button>
                        <span className="pt-3 mx-3 font-bold">
                          {item.quantity}
                        </span>
                        <button
                          onClick={() => handleIncrease(item.productId._id)}
                          className="quantity__value increase"
                        >
                          +
                        </button>
                      </div>
                      <button
                        onClick={() => handleRemoveFromCart(item.productId._id)}
                        className="remove-item"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="minicart__amount">
                <div className="minicart__amount_list d-flex justify-content-between">
                  <span>sub Total:</span>
                  <span>
                    <b>
                     
                      {formatPriceETB((item.productId.price * item.quantity).toFixed(2))}
                    </b>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
        <div className="minicart__amount_list d-flex justify-content-between mt-5 border-b-8 border-red-700">
          <span>Total:</span>
          <span>
            <b> {formatPriceETB(totalPrice)}</b>
           
          </span>
        </div>

        <div className="minicart__conditions text-center">
          <input
            className="minicart__conditions--input"
            id="accept"
            type="checkbox"
          />
          <label className="minicart__conditions--label" htmlFor="accept">
            I agree with the{" "}
            <Link className="minicart__conditions--link" to="/privacyandpolicy">
              Privacy and Policy
            </Link>
          </label>
        </div>
        <div className="minicart__button d-flex justify-content-center">
          <Link className="primary__btn minicart__button--link" to="/cart">
            View cart
          </Link>
          <Link className="primary__btn minicart__button--link" to="/checkout">
            Checkout
          </Link>
        </div>
      </div>

      <OffcanvasSidebar
        openTrigger=".minicart__open--btn"
        closeTrigger=".minicart__close--btn"
        wrapper=".offCanvas__minicart"
      />
    </div>
  );
}

export default Minicart;
