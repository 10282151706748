import React from 'react'
import Htopbar from './Headercomp/Htopbar'
import Hmain from './Headercomp/Hmain'
import Hmain2 from './Headercomp/Hmain2'
import Hmain3 from './Headercomp/Hmain3'

function Header() {
    return (
        <div className="App">
            <header className="header__section ">
                <Htopbar />
                <Hmain />
                <Hmain2 />
                <Hmain3 />
            </header>
        </div>
    )
}

export default Header