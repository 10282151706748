import axios from "axios";
import { base_url, config } from "../../utils/axiosConfig";

const getBlogs = async () => {
    try {
        const response = await axios.get(`${base_url}blog`, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching blogs:', error.message);
        throw error; // Re-throw the error for Redux to handle
    }
};

const getBlog = async (id) => {
    try {
        const response = await axios.get(`${base_url}blog/${id}`, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching blog:', error.message);
        throw error; // Re-throw the error for Redux to handle
    }
};

export const blogService = {
    getBlogs,
    getBlog,
};
