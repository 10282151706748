import axios from 'axios';
import { base_url } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
// Base URL for the API

// Action Types
export const GET_WISHLIST_SUCCESS = 'GET_WISHLIST_SUCCESS';
export const GET_WISHLIST_FAILURE = 'GET_WISHLIST_FAILURE';
export const ADD_TO_WISHLIST_SUCCESS = 'ADD_TO_WISHLIST_SUCCESS';
export const ADD_TO_WISHLIST_FAILURE = 'ADD_TO_WISHLIST_FAILURE';
export const DELETE_FROM_WISHLIST_SUCCESS = 'DELETE_FROM_WISHLIST_SUCCESS';
export const DELETE_FROM_WISHLIST_FAILURE = 'DELETE_FROM_WISHLIST_FAILURE';
export const GET_WISHLIST_TOTAL = 'GET_WISHLIST_TOTAL';
export const GET_WISHLIST_TOTAL_SUCCESS = 'GET_WISHLIST_TOTAL_SUCCESS';
export const GET_WISHLIST_TOTAL_FAILURE = 'GET_WISHLIST_TOTAL_FAILURE';


export const getWishlist = () => async (dispatch) => {
    try {
    // Retrieve customer data from localStorage
    const customerData = JSON.parse(localStorage.getItem('customer'));

    if (!customerData || !customerData.token) {
      throw new Error('User is not authenticated or token is missing.');
    }

    const token = customerData.token;

    // Set the request configuration with authorization header
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
        const response = await axios.get(`${base_url}wishlist/`, config);

        dispatch({
            type: GET_WISHLIST_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        dispatch({
            type: GET_WISHLIST_FAILURE,
            payload: error.message,
        });
    }
};

// wishListAction.js
// wishListAction.js
export const addToWishlist = (productId, navigate) => async (dispatch) => {
    try {
        // Retrieve customer data from localStorage
        const customerData = JSON.parse(localStorage.getItem('customer'));

        if (!customerData || !customerData.token) {
            toast.error('Please log in to add items to your wishlist.');

            // Use the navigate function passed from the component
            return navigate('/login');
        }

        const token = customerData.token;

        // Set the request configuration with authorization header
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        };

        // Send productId to the backend
        const response = await axios.post(`${base_url}wishlist/add`, { productId }, config);

        dispatch({
            type: ADD_TO_WISHLIST_SUCCESS,
            payload: response.data,
        });
        dispatch(getWishlist());
        dispatch(getWishlistTotal());
        toast.success('Item added to wishlist successfully');
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        dispatch({
            type: ADD_TO_WISHLIST_FAILURE,
            payload: error.message,
        });

        // Show an error toast
        toast.error('Failed to add item to wishlist.');
    }
};


export const deleteFromWishlist = (id) => async (dispatch) => {
    try {
    // Retrieve customer data from localStorage
    const customerData = JSON.parse(localStorage.getItem('customer'));

    if (!customerData || !customerData.token) {
      throw new Error('User is not authenticated or token is missing.');
    }

    const token = customerData.token;

    // Set the request configuration with authorization header
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
        console.log(`Deleting wishlist item with ID: ${id}`);

        await axios.delete(`${base_url}wishlist/remove/${id}`, config);


        dispatch({
            type: DELETE_FROM_WISHLIST_SUCCESS,
            payload: id,
        });
        dispatch(getWishlist());
        dispatch(getWishlistTotal());
        toast.success('Item Deleted successfully');
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        dispatch({
            type: DELETE_FROM_WISHLIST_FAILURE,
            payload: error.message,
        });
    }
};

export const getWishlistTotal = () => async (dispatch) => {
    try {
    // Retrieve customer data from localStorage
    const customerData = JSON.parse(localStorage.getItem('customer'));

    if (!customerData || !customerData.token) {
      throw new Error('User is not authenticated or token is missing.');
    }

    const token = customerData.token;

    // Set the request configuration with authorization header
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
        const response = await axios.get(`${base_url}wishlist/total`, config);

        dispatch({
            type: GET_WISHLIST_TOTAL_SUCCESS,
            payload: response.data.total,
        });
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        dispatch({
            type: GET_WISHLIST_TOTAL_FAILURE,
            payload: error.message,
        });
    }
};
